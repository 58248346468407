import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../components/layout"

const Success = (props) => (
    <Layout>
        <div>
            <Helmet>
                <title>Claudio Teixeira</title>
                <meta name="description" content="Claudio Teixeira" />
            </Helmet>

            <div id="main" className="alt landing">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h2>Senior Full Stack Web Software Engineer (MsC)</h2>
                        </header>

                        <p> <strong> Hello there!! <span role="img" aria-label="thumb"> 👍</span> </strong> </p>
                        <p> <i> I can <strong> help you </strong> and your company on all stages of your web or mobile product development process (state of the art
industry standards) from Frontend, Backend, Message Queues, CI/CD Operations, Cloud Infrastructure provisioning and
management, Telemetry, Security, Compliance and Quality Assurance.
                        </i>
                        </p>
                    </div>

                    <p> I'm  specialised in helping startups <span role="img" aria-label="unicorn"> 🦄 </span> across all stages of web product <strong> development </strong>, <strong> deployment </strong> and <strong> operations </strong>, leading and managing development teams, architecting and building commercial large scale web applications. </p>

                    <div className="flow">
                        <a className="flow__step" data-title="Planning" href="javascript:;"/>
                        <a className="flow__step" data-title="Development" href="javascript:;"/>
                        <a className="flow__step" data-title="Testing/CI" href="javascript:;"/>
                        <a className="flow__step" data-title="Packaging" href="javascript:;"/>
                        <a className="flow__step" data-title="Release CD" href="javascript:;"/>
                        <a className="flow__step" data-title="Infrastructure" href="javascript:;"/>
                        <a className="flow__step" data-title="Telemetry" href="javascript:;"/>
                        <a className="flow__step" data-title="Security" href="javascript:;"/>
                    </div>

                    {/* <div className="lanes">
                        <ul className="lane">
                            <li className="lane__item"><a className="step" href="javascript:;">Planning(Time/Costs)</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Development(Javascript)</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Testing(CI)</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Packaging(Docker)</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Release(CD)</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Infrastructure(K8s)</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Monitor/Telemetry</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Scalability(Cloud)</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Security</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Availability</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Maintenance</a></li>
                            <li className="lane__item"><a className="step" href="javascript:;">Customer Success</a></li>
                        </ul>
                    </div> */}
                    <p> <strong> Industries: </strong> Sportsbook, Online Gambling, Online Casinos, Advertisement, Software as a Service (SaaS) tools within others). </p>
                    <p>
                        Up to 6 years <span role="img" aria-label="star"> 🌟</span>  experience in web development.
                        Previous roles: Researcher, Tech Leader, Team Manager, Full Stack Developer and CTO in several companies from 5 up to 50 employees.
                    </p>
                    <p> You can find my blog, gist, playground at: <a target="_blank" rel="noopener noreferrer" href="https://www.coderecipes.org"> coderecipes.org </a> </p>
                    <p> My CV in English (PDF): <a target="_blank" rel="noopener noreferrer" href="./claudio-cv-v1.0.2.pdf"> Download </a> </p>

                    <p>
                        <a target="_blank" rel="noopener noreferrer" href="https://github.com/claudiotx"> <img className="social" src="../GitHub-Mark-64px.png" alt="Github"></img> </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/cl%C3%A1udio-teixeira-138a9a3a/"> <img className="social" src="https://cdn-salecycle.s3.amazonaws.com/dev/SocialMedia/Black_Circle_LinkedIn.png" alt="Made in Portugal" /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/claudiotx7"> <img className="social" src="https://cdn-salecycle.s3.amazonaws.com/dev/SocialMedia/Black_Circle_Twitter.png" alt="Made in Portugal" /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/claudiotx?ref=bookmarks"> <img className="social" src="https://cdn-salecycle.s3.amazonaws.com/dev/SocialMedia/Black_Circle_Facebook.png" alt="Facebook"></img> </a>                        
                    </p>

                    <a target="_blank" rel="noopener noreferrer" href="https://www.visitportugal.com/pt-pt"> <img src="https://img.shields.io/badge/MADE%20IN-PORTUGAL♥-brightgreen.svg?style=for-the-badge" alt="Made in Portugal" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org"> <img src="https://img.shields.io/badge/JS%20FRAMEWORK-GATSBY-brightgreen.svg?style=for-the-badge" alt="Gatsby"/></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.nginx.org"> <img src="https://img.shields.io/badge/WEB%20SERVER-NGiNX-brightgreen.svg?style=for-the-badge" alt="Gatsby"/></a>
                    <a target="_blank" rel="noopener noreferrer"href="https://www.docker.org"> <img src="https://img.shields.io/badge/CD-Docker-brightgreen.svg?style=for-the-badge" alt="Gatsby"/></a>
                </section>
            </div>

        </div>
    </Layout>
)

export default Success